import React from "react";
import { SEO, PageLayout, LocationsSorted } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"locations-page"}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"component-section-container"}
          >
            <Grid.Column width={12}>
              <LocationsSorted
                locations={fishermanBusiness.locations.map((location) => {
                  const locationNameToOrderLink = {
                    Orlando:
                      "https://www.toasttab.com/antonios-house-of-pizza-kirkman-4626-s-kirkman-rd",
                    Kissimmee:
                      "https://www.toasttab.com/antonios-house-of-pizza-kissimmee-2362-east-irlo-bronson-memorial-highway-ste-a103",
                    Apopka:
                      "https://order.toasttab.com/online/antonios-house-of-pizza-apopka-1097-w-orange-blossom-trail",
                  };
                  return {
                    ...location,
                    actions: [
                      {
                        url: locationNameToOrderLink[location.name],
                        label: "Order Online",
                      },
                    ],
                  };
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      locations {
        name
        timezone
        phoneNumber
        email
        hours {
          open
          close
          day
          label
        }
        street
        city
        state
        zipCode
        country
        latitude
        longitude
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Locations" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
